@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700);
body {
  margin: 0;
  padding: 100px 0 0 0;
  font-family: 'Dosis', sans-serif;
  font-weight: 500;
  background-color: #eeeeee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #2c3e50;
  color: #ffffff;
  z-index: 10; }
  .navbar-search {
    width: 100%;
    padding: 12px;
    margin-right: 10px;
    border: 0;
    border-radius: 5px;
    font-size: 15px;
    background-color: #1b2631;
    color: #ffffff; }
  .navbar-search::-webkit-input-placeholder {
    color: #dddddd; }
  .navbar-search::-ms-input-placeholder {
    color: #dddddd; }
  .navbar-search::placeholder {
    color: #dddddd; }
  .navbar-logo {
    margin: 10px;
    color: #ffffff; }
  .navbar-right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px; }
    .navbar-right-icon {
      font-size: 30px; }
  .navbar-items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #1b2631;
    width: 100%; }
    .navbar-items-big {
      display: flex;
      width: 100%;
      justify-content: space-evenly; }
    .navbar-items-small {
      display: none;
      width: 100%; }
    .navbar-items-dropdown {
      flex-direction: column; }
  .navbar-links {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    padding: 8px 10px;
    text-align: center;
    cursor: pointer; }
    .navbar-links-active {
      background-color: #2c3e50; }
    .navbar-links-store {
      color: #00ff23; }
  .navbar-links:hover, .navbar-links:focus {
    background-color: #2c3e50; }

.listItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  min-height: 400px;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 3px #dddb; }
  .card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .card-image {
    max-width: 300px;
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .card-title {
    width: calc(100% - 40px);
    padding: 10px 20px 0;
    font-size: 18px;
    font-weight: 600; }
  .card-price {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: calc(100% - 40px);
    padding: 10px 20px; }
    .card-price-slashed {
      font-size: 20px;
      color: #222222;
      text-decoration: line-through; }
    .card-price-real {
      font-size: 30px;
      font-weight: 400; }
  .card-likes {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: calc(100% - 40px);
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0; }
    .card-likes-text {
      color: #222222;
      margin-right: 10px; }
    .card-likes-icon {
      font-size: 20px;
      color: #aa0000; }
  .card-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 50px);
    margin: 15px;
    padding: 10px;
    border-radius: 3px;
    background-color: #2c3e50;
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    box-shadow: 2px 2px 10px 5px #dddb; }
    .card-button-text {
      margin: 0; }
    .card-button-logo {
      max-height: 20px;
      max-width: 90px; }
    .card-button-arrow {
      font-size: 30px; }
  .card:hover {
    box-shadow: 7px 7px 10px 0px #2c3e50aa;
    transition: all 200ms ease;
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px); }

.pageLoader {
  display: flex;
  height: calc(100vh - 100px);
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #2c3e50; }

.imageLoader {
  display: flex;
  height: 200px;
  width: 300px;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  color: #2c3e50; }

.store-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 20px;
  height: 100px;
  width: 260px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #dddb; }
  .store-wrapper:hover {
    box-shadow: 2px 2px 10px 5px #dddb; }

.store-image {
  max-width: 100%;
  max-height: 100%; }

.share-container {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20; }

.mailchimp {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;
  width: 30%;
  margin-bottom: 20px;
  background-color: #72af46; }
  .mailchimp-top {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    padding-bottom: 0;
    width: calc(100% - 10px); }
  .mailchimp-bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    width: calc(100% - 10px); }
  .mailchimp-text {
    width: calc(100% - 10px);
    text-align: center;
    color: #ffffff;
    font-size: 18px; }
    .mailchimp-text-thanks {
      padding-bottom: 5px; }
  .mailchimp-cross {
    font-size: 20px;
    color: #ffffff;
    cursor: pointer; }
  .mailchimp-input {
    width: calc(100% - 95px);
    padding: 12px;
    padding-right: 0;
    border: 0;
    font-size: 12px; }
  .mailchimp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    border-left: 1px solid #72af46;
    background-color: #ffffff;
    cursor: pointer; }
  .mailchimp-error {
    display: block;
    text-align: center;
    color: #aa0000;
    font-size: 14px;
    padding-bottom: 5px; }

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #2c3e50; }
  .footer-text {
    width: calc(100% - 60px);
    padding: 30px;
    font-size: 15px;
    text-align: center;
    color: #ffffff; }

a {
  text-decoration: none;
  color: #000000; }

@media (max-width: 769px) {
  body {
    padding-top: 160px; }
  .navbar {
    justify-content: center; }
    .navbar-right {
      width: 100%; }
    .navbar-logo {
      margin-bottom: 0; }
    .navbar-items-small {
      display: flex; }
    .navbar-items-big {
      display: none; }
    .navbar-links {
      width: inherit; }
  .pageLoader {
    height: calc(100vh - 160px); }
  .mailchimp {
    width: 100%;
    margin-bottom: 0; } }

@media (max-width: 375px) {
  body {
    padding-top: 130px; }
  .navbar-right-icon {
    font-size: 20px; }
  .navbar-search {
    font-size: 12px;
    padding: 10px; }
  .navbar-logo {
    font-size: 20px; }
  .card {
    width: 250px;
    min-height: 300px; }
    .card-button {
      font-size: 15px; }
    .card-image {
      max-width: 225px;
      height: 150px; }
  .imageLoader {
    height: 167px;
    width: 250px; }
  .pageLoader {
    height: calc(100vh - 130px); } }

