@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700');

body {
  margin: 0;
  padding: 100px 0 0 0;
  font-family: 'Dosis', sans-serif;
  font-weight: 500;
  background-color: #eeeeee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
